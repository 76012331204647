<template>
  <v-dialog v-model="visible" transition="dialog-top-transition" width="500">
    <v-card>
      <v-card-title class="text-h5 primary">
        <p v-if="subscriptions" style="color: #ffffff">Insufficient Credits</p>
        <p v-else style="color: #ffffff">Purchase Report</p>
      </v-card-title>
      <v-card-text>
        <p v-if="subscriptions">
          Apologies {{ user.displayName }}, it appears as though you may have
          reached your maximum report credit limits for the month. If you would
          like to upgrade or change your subscription please visit
          <router-link :to="{ name: 'My Account' }">My Account</router-link>.
        </p>
        <p v-else>
          You do not currently hold a PropCheck subscription. If you would like
          to add a subscription please visit
          <router-link :to="{ name: 'My Account' }">My Account</router-link>.
          Alternatively, click Purchase button below to proceed to purchase this
          single report.
        </p>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="subscriptions"
          color="primary"
          text
          @click="closeOutOfCreditsDialog"
        >
          Close
        </v-btn>
        <div v-else>
          <v-btn text @click="closeOutOfCreditsDialog">
            Close
          </v-btn>
          <v-btn color="primary" text @click="purchaseReportWithOneTimeURL">
            Purchase
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const auth = firebase.auth();
const db = firebase.firestore();
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

const EVENT_NAME = "close-out-of-credits-dialog";
export default {
  props: {
    value: Boolean,
    selectedAddress: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loader: null,
      loading: false,
      overlay: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(EVENT_NAME);
      },
    },
    ...mapState({
      user: state => state.user.user,
      roles: state => state.user.roles,
      credits: state => state.user.credits,
      subscriptions: state => state.user.subscriptions,
    }),
  },
  created() {
    this.$store.dispatch("retrieveSubscriptions");
  },
  methods: {
    closeOutOfCreditsDialog: function() {
      this.$emit(EVENT_NAME);
    },
    purchaseReportWithOneTimeURL: async function() {
      // console.log("outputting something and the event ");

      if (!this.user || !auth.currentUser.uid) {
        // no user logged in so send to login page
        this.showLoginRequiredDialog = true;
        // this.$route.push('/pages/login')
        return;
      }

      // subcollection search for all prices that are type "one_time" and "active" - requires special firebase.rules
      var prices = db
        .collectionGroup("prices")
        .where("type", "==", "one_time")
        .where("active", "==", true); // .where("role","==","oneoff");

      await prices.get().then(async querySnapshot => {
        querySnapshot.forEach(async doc => {
          // logs the price info 
          // console.log(doc.id, " => ", doc.data());
          var aPrice = doc.data();

          // consider if we need to error if multiple results found here...? 
          // if (prices.length > 0){
          //   console.log("found more than one active one_time report product... error");
          // }
          const selectedPrice = aPrice;

          this.overlay = true;
          this.loader = {};
          this.loading = true;

          // support one time prices
          // https://github.com/stripe/stripe-firebase-extensions/blob/next/firestore-stripe-subscriptions/CHANGELOG.md#version-0112---2021-04-29
          // add mode: "payment" here
          const isOneTime = selectedPrice.type === "one_time";
          // NOTE: the URL passed to the checkout method is the KEY DIFFERENCE in this checkout process to the one on the subscribe page
          let checkoutInfo = {
            price: doc.id,
            allow_promotion_codes: true,
            success_url:
              window.location.origin +
              "/#/pages/search?autoGenerate=true&selectedAddress=" +
              encodeURIComponent(this.selectedAddress),
            cancel_url: window.location.origin + "/#/pages/search?selectedAddress=" +
              encodeURIComponent(this.selectedAddress),
          };

          if (isOneTime) {
            checkoutInfo = {
              ...checkoutInfo,
              mode: "payment",
            };
          }

          const docRef = await db
            .collection("users")
            .doc(auth.currentUser.uid)
            .collection("checkout_sessions")
            .add(checkoutInfo);

          // Wait for the CheckoutSession to get attached by the extension
          docRef.onSnapshot(snap => {
            const { sessionId } = snap.data();
            if (sessionId) {
              // We have a session, let's redirect to Checkout
              // stripe setup on the window at start of class
              this.loader = null;
              this.loading = true;
              this.overlay = false;
              stripe.redirectToCheckout({ sessionId });
            }
          });
        });
      });
    },
  },
};
</script>
